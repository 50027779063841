import { tw } from '@/utils/tw';
import CheckboxTheme from 'base/components/Checkbox/theme';

const { Caption } = CheckboxTheme;

const Checkbox = tw.theme({
  extend: CheckboxTheme,
  slots: {
    label: ['text-input-md'],
    input: [
      'active:before:scale-0',
      'active:bg-gray-100',
      'active:shadow-black',
      'after:-translate-x-1/2',
      'after:-translate-y-2/3',
      'after:absolute',
      'after:block',
      'after:border-b-2',
      'after:border-black',
      'after:border-l-2',
      'after:h-1.25',
      'after:left-1/2',
      'after:origin-center',
      'after:scale-0',
      'after:top-1/2',
      'after:transition-all',
      'after:w-2.5',
      'appearance-none',
      'before:-translate-x-1/2',
      'before:-translate-y-1/2',
      'before:-z-2',
      'before:absolute',
      'before:bg-royal-blue-200',
      'before:block',
      'before:h-[170%]',
      'before:left-1/2',
      'before:rounded-full',
      'before:scale-0',
      'before:top-1/2',
      'before:transition-all',
      'before:w-[170%]',
      'bg-white',
      'checked:after:-rotate-45',
      'checked:after:scale-100',
      'focus:before:scale-100',
      'focus:shadow-inset-2',
      'focus:shadow-royal-blue-700',
      'h-5',
      'hover:before:scale-0',
      'hover:shadow-black',
      'hover:shadow-inset-2',
      'p-1.25',
      'relative',
      'shadow-black',
      'shadow-inset-1',
      'transition-all',
      'w-5',
    ],
  },
  variants: {
    ...CheckboxTheme.variants,
  },
});

export default Object.assign(Checkbox, { Caption });
